<template>
  <div>
    <h3>Application Details</h3>
    <div class="row" v-if="! loading">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Position</label>
                <h4><a href="#" @click.prevent="show_description = true">{{ application.career.title }}</a></h4>
              </div>
              <div class="col-md-6">
                <label>Applicant Name</label>
                <p>{{ application.name }}</p>
              </div>
              <div class="col-md-6">
                <label>Email</label>
                <p>{{ application.email }}</p>
              </div>
              <div class="col-md-6">
                <label>Phone Number</label>
                <p>{{ application.phone_number }}</p>
              </div>
              <div class="col-md-6">
                <label>Age</label>
                <p>{{ application.age }}</p>
              </div>
              <div class="col-md-6">
                <label>Gender</label>
                <p>{{ application.gender }}</p>
              </div>
              <div class="col-md-12">
                <label>Introduction</label>
                <p>{{ application.intro }}</p>
              </div>
              <hr>
              <div class="col-md-12">
                <label>CV</label>
                <p><a :href="application.cv" target="_blank"><i class="lni lni-download me-2"></i>Download Document</a></p>
              </div>
              <div class="col-md-12" v-if="application.docs.length > 0">
                <label>Other Documents</label>
                <p v-for="(doc, i) in application.docs" :key="`doc-${i}`">
                  <a :href="doc" target="_blank"><i class="lni lni-download me-2"></i>Download Document</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h5 class="text-muted">Actions</h5>
        <div v-if="application.status == 'pending'">
          <a href="#" @click.prevent="setStatus('approved')" class="btn btn-success d-grid mb-2">Approve Application</a>
          <a href="#" @click.prevent="setStatus('declined')" class="btn btn-warning d-grid mb-2">Decline Application</a>
          <a href="#" @click.prevent="destroy" class="btn btn-danger d-grid">Delete Application</a>
        </div>
        <div v-if="application.status == 'approved'">
          <router-link :to="{ name: 'admin.users.show', params: { id: application.user_id }}" class="btn btn-primary d-grid">View Profile</router-link>
        </div>
      </div>
    </div>
    <loading v-if="loading" />
    <modal :show="show_description" @close="show_description = false">
      <template slot="header">Job Description</template>
      <div class="server-content" v-html="application.career.content"></div>
    </modal>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      loading: true,
      application: { docs: [], career: {}},
      show_description: false
    }
  },

  methods: {
    fetch() {
      this.loading = true

      this.$axios.get(`/api/v1/admin/career-applications/${this.$route.params.id}`).then(response => {
        this.application = response.data.application
        this.loading = false
      })
    },

    setStatus(status) {
      this.loading = true
      let data = {
        status: status
      }

      this.$axios.put(`/api/v1/admin/career-applications/${this.$route.params.id}`, data).then(() => {
        this.loading = false
        this.fetch()
      })
    },

    destroy() {
      this.$axios.delete(`/api/v1/admin/career-applications/${this.$route.params.id}`).then(() => {
        this.$router.push({ name: 'admin.career-applications.index' })
      })
    }
  }
}
</script>